<template>
  <div class="max-width table">
    <CustomForm
      method="getAllRefundForFranchisee"
      ref="CustomForm"
      @ok="setData"
      :params="{ status: [9, 10, 11, 12, 13, 14, 15], is_detail: true }"
      :formItems="[
        { type: 'input', label: '申购单号', key: 'code', note: '搜索申购单号' },
        { type: 'input', label: '产品名称', key: 'name', note: '搜索产品名称' },
        { type: 'input', label: '产品编号', key: 'sn_product_info', note: '搜索产品编号' },
        { type: 'select', label: '订单状态', key: 'status', note: '默认显示全部', default: 'refund', option: [
          { value: 'refund', label: '全部' },
          { value: 9, label: '退货申请中' }, // 9 蓝色
          { value: 10, label: '退货待发货' }, // 10 红色
          { value: 11, label: '退货中' }, // 11 黑色
          { value: 12, label: '待退款' }, // 12 黄色
          { value: 13, label: '已退货' }, // 13 绿色
          { value: 14, label: '拒绝退货' }, // 14 灰色
          { value: 15, label: '退货完成' }
        ] },
        { type: 'time', label: '下单时间段', key: 'create_time' },
      ]"
    >
      <!-- 产品列表 -->
      <a-table
        :row-key="record => record.id_order_refund"
        :data-source="data"
        :pagination="false"
        :expandIconColumnIndex="2"
        :columns="[
        { title: '退货单号', dataIndex: 'code', width: 130, align: 'center' },
        { title: '产品信息', dataIndex: 'small_image', scopedSlots: { customRender: 'small_image' }, width: 120, align: 'center' },
        //{ title: '名称', dataIndex: 'name', scopedSlots: { customRender: 'name' }, align: 'center' },
        //{ title: '退货数量', dataIndex: 'refund_amount', align: 'center' },
        //{ title: '单位', dataIndex: 'product_unit', align: 'center' },
        { title: '订单金额', dataIndex: 'order_money', align: 'center' },
        { title: '退款金额', dataIndex: 'need_refund_money', scopedSlots: { customRender: 'need_refund_money' }, align: 'center' },
        { title: '订单状态', dataIndex: 'status', scopedSlots: { customRender: 'status' }, align: 'center' },
        //{ title: '物流公司', dataIndex: 'refund_express_info1', scopedSlots: { customRender: 'delivery_company' }, align: 'center' },
        //{ title: '物流单号', dataIndex: 'refund_express_info2', scopedSlots: { customRender: 'delivery_id' }, align: 'center' },
        //{ title: '物流电话', dataIndex: 'refund_express_info3', scopedSlots: { customRender: 'delivery_phone' }, align: 'center' },
        { title: '操作', scopedSlots: { customRender: 'action' }, align: 'center' },
      ]"
      >
        <span slot="small_image" slot-scope="text, record">
          <div class="image">
            <span class="item-numbers">共{{record.refund_amount}}件产品</span>
            <img mode="aspectFix" v-lazy="text" />
          </div>
          <router-link :to="{ name: 'product', query: { id: record.id_product_info }}">
            <a class="theme">{{ record.name }}</a>
          </router-link>
        </span>

        <span slot="need_refund_money" slot-scope="text,record">
          <a>-{{ record.need_refund_money }}</a>
        </span>
        <span slot="name" slot-scope="text, record">
          <router-link :to="{ name: 'product', query: { id: record.id_product_info }}">
            <a class="theme">{{ text }}</a>
          </router-link>
        </span>
        <!-- <span slot="delivery_company" slot-scope="text, record">
          <span
            style="word-break:break-all;"
          >{{record.refund_express_info.substring(0,record.refund_express_info.indexOf('~!*'))}}</span>
        </span>
        <span slot="delivery_id" slot-scope="text, record">
          <span
            style="word-break:break-all;"
          >{{record.refund_express_info.substring(record.refund_express_info.substring(0,record.refund_express_info.indexOf('~!*')).length+3,record.refund_express_info.lastIndexOf('~!*'))}}</span>
        </span>
        <span slot="delivery_phone" slot-scope="text, record">
          <span
            style="word-break:break-all;"
          >{{record.refund_express_info.substring(record.refund_express_info.lastIndexOf('~!*')+3)}}</span>
        </span>-->
        <span slot="status" slot-scope="text, record">
          <span :style="'color:'+status[record.status].color">{{ status[record.status].label }}</span>
        </span>
        <span slot="action" slot-scope="text, record">
          <!-- 查看物流 -->
          <a class="text" @click="modalData = record; modalName.logistics = true">查看物流</a>
          <a-divider type="vertical" />
          <!-- 详情 -->
          <a
            type="link"
            style="padding: 0"
            @click="modalData = record; modalName.refund = true"
            class="text"
          >详情</a>
          <!-- 退货 -->
          <a-divider type="vertical" v-if="record.status===10" />
          <a
            v-if="record.status === 10"
            class="yellow text"
            type="link"
            @click="modalData = record; modalName.express = true"
          >发货</a>
          <a-divider type="vertical" v-if="record.status > 8 && record.status < 15" />
          <a
            class="black text"
            v-if="record.status > 8 && record.status < 15"
            @click="showContract(record)"
          >合同</a>
          <a-divider type="vertical" v-if="record.status === 13" />
          <a class="text" v-if="record.status === 13" @click="confirmRefund(record)">收到退款确认</a>
        </span>
        <a-table
          size="small"
          slot="expandedRowRender"
          slot-scope="record"
          :row-key="record => record.id_order_cart"
          :data-source="record.cart"
          :pagination="false"
          :columns="[
            { title: '申购单号', dataIndex: 'code', width: 130, align:'center' },
            { title: '产品', dataIndex: 'url', scopedSlots: { customRender: 'url' }, align:'center' },
            { title: '名称', dataIndex: 'name', scopedSlots: { customRender: 'name' }, align:'center' },
            { title: '供应商', dataIndex: 'company_name', scopedSlots: { customRender: 'company_name' }, align:'center' },
            { title: '退货数量', dataIndex: 'refund_amount', align:'center' },
            { title: '单位', dataIndex: 'product_unit', width: 80, align:'center' },
            { title: '退货金额', dataIndex: 'refund_money', align:'center' },
            { title: '折后价格', dataIndex: 'real_money', align:'center' },
            { title: '状态', dataIndex: 'status', scopedSlots: { customRender: 'status' }, width: 250, align:'center' },
          ]"
        >
          <span slot="url" slot-scope="text" style="display:flex;justify-content:center">
            <div class="image">
              <img mode="aspectFix" v-lazy="text" />
            </div>
          </span>
          <span slot="company_name" slot-scope="text, record">
            <div v-if="record.visible">{{text}}</div>
          </span>
          <span slot="name" slot-scope="text, record">
            <router-link :to="{ name: 'product', query: { id: record.id_product_info }}">
              <a class="theme" style="text-decoration: underline;">{{ text }}</a>
            </router-link>
          </span>
          <!-- 订单状态 -->
          <span slot="status" slot-scope="text, record" style="display:flex;flex-direction:column">
            <span :style="'color:'+status[record.status].color">
              {{ status[record.status].label }}
              <span
                v-if="record.inner_remaining_time"
                class="red"
              >{{ record.inner_remaining_time }}</span>
            </span>
          </span>
        </a-table>
      </a-table>
    </CustomForm>
    <!-- 查看物流弹框 -->
    <a-modal v-model="modalName.logistics" title="物流信息" @ok="modalName.logistics=false">
      <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 18 }">
        <a-form-item label="物流公司" style="margin: 20px 0">{{modalData.refund_express_info_company}}</a-form-item>
        <a-form-item label="物流单号">{{modalData.refund_express_info_id}}</a-form-item>
        <a-form-item label="物流电话">{{modalData.refund_express_info_phone}}</a-form-item>
      </a-form>
    </a-modal>
    <!-- 申请退货弹框 -->
    <a-modal v-model="modalName.refund" title="申请退货" :footer="null">
      <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 18 }">
        <!-- <a-form-item label="产品名称" style="margin: 20px 0">{{ modalData.name }}</a-form-item> -->
        <!-- <a-form-item label="订单总金额">￥{{ (modalData.amount * modalData.price_settle).toFixed(2) }}</a-form-item> -->
        <a-form-item label="退款金额">{{ -modalData.need_refund_money }}</a-form-item>
        <a-form-item label="退货理由">{{ modalData.refund_reason }}</a-form-item>
        <a-form-item label="产品照片">
          <img class="modal-img" v-image-preview v-for="url in modalData.url" :key="url" :src="url" />
        </a-form-item>
        <a-form-item label="审批意见">{{ modalData.content }}</a-form-item>
      </a-form>
    </a-modal>
    <!-- 发货弹框 -->
    <a-modal
      v-model="modalName.express"
      title="发货"
      okText="提交"
      @ok="refundShip"
      :okButtonProps="{ props: { loading: isLoadTable } }"
    >
      <a-form>
        <a-alert description="温馨提示：退货成功后，退款在15个工作日内原路退回至汇款账户。" type="warning" show-icon />
        <a-form-item label="收货地址" style="margin-top:20px">{{ modalData.shipping_info }}</a-form-item>
        <a-form-item label="发货物流公司">
          <a-input
            type="text"
            placeholder="请填写您发货的物流公司"
            :max="255"
            v-model="
            modalData.company"
          />
        </a-form-item>
        <a-form-item label="发货快递单号">
          <a-input
            type="text"
            placeholder="请填写您发货的快递单号"
            :max="255"
            v-model="
            modalData.delivery_id"
          />
        </a-form-item>
        <a-form-item label="对方联系方式">
          <a-input
            type="text"
            placeholder="请填写联系方式"
            :max="255"
            v-model="
            modalData.phone"
          />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>
<script>
import CustomForm from '@/component/CustomForm.vue'
export default {
  data() {
    return {
      data: [], // 所有订单
      selectedData: [], // 选中的产品
      selectedDataKey: [], // 选中的产品id
      totalPrice: 0, // 总价
      modalData: {}, // 弹框数据
      modalName: this.hideModal(), // 弹框名称
      isLoadTable: false
    }
  },

  methods: {
    setData() {
      // this.data = res
      this.isLoadTable = false
      this.data.forEach((e) => {
        if (e.refund_express_info.length) {
          e.refund_express_info_company = e.refund_express_info.substring(
            0,
            e.refund_express_info.indexOf('~!*')
          )
          e.refund_express_info_id = e.refund_express_info.substring(
            e.refund_express_info.substring(
              0,
              e.refund_express_info.indexOf('~!*')
            ).length + 3,
            e.refund_express_info.lastIndexOf('~!*')
          )
          e.refund_express_info_phone = e.refund_express_info.substring(
            e.refund_express_info.lastIndexOf('~!*') + 3
          )
        }
      })
    },
    refundShip() {
      if (
        !(
          this.modalData.company &&
          this.modalData.delivery_id &&
          this.modalData.phone
        )
      ) {
        return this.$message.error('请完善物流信息')
      }
      this.modalData.expressInfo = (
        this.modalData.company +
        '~!*' +
        this.modalData.delivery_id +
        '~!*' +
        this.modalData.phone
      ).toString()
      // console.log(this.modalData.expressInfo)
      this.isLoadTable = true
      this.post('shipRefundForFranchisee', {
        id_order_refund: this.modalData.id_order_refund,
        refund_express_info: this.modalData.expressInfo
      })
        .then(() => {
          this.$message.success('信息已提交，等待供应商收货')
          this.$refs.CustomForm.toPage(1)
          this.isLoadTable = false
          this.hideModal()
        })
        .catch((err) => {
          this.$message.error(err.msg)
          this.isLoadTable = false
        })
    },
    hideModal() {
      return (this.modalName = {
        express: false,
        refund: false,
        logistics: false
      })
    },
    // 合同详情
    showContract(order) {
      const routeData = this.$router.resolve({
        name: 'refundcontract',
        query: { contract: order.code, id_order_master: order.id_order_master }
      })
      window.open(routeData.href, '_blank')
    },
    // 收到退款确认
    confirmRefund(record) {
      this.post('confirmRefundForFranchisee', {
        id_order_refund: record.id_order_refund
      })
        .then(() => {
          this.$message.success('退货完成')
          this.$refs.CustomForm.toPage(1)
          this.$refs.CustomForm.isLoadingTable = false
        })
        .catch((err) => {
          this.$message.error(err.msg)
        })
    }
  },

  components: {
    CustomForm
  },

  props: ['status'],

  name: 'Received'
}
</script>

<style scoped>
.table {
  min-height: 600px;
  text-align: center;
}
.image {
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
}
.image img {
  width: 100%;
  /* max-height: 100%; */
}
.buy {
  width: 100%;
  height: 50px;
  border-bottom: 1px solid #e7e7e7;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.buy .button {
  text-align: center;
  background: var(--theme);
  color: #fff;
  width: 200px;
  height: 50px;
  margin-left: 30px;
  line-height: 50px;
  font-size: 22px;
  cursor: pointer;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}
.buy .button:hover {
  transform: scale(1.03);
}
.modal-img {
  width: 100px;
  height: 100px;
  margin: 0 10px 10px 0;
  border-radius: 5px;
}
/* 和 upload 有关 */
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
/* 文字下划线 */
.text:hover {
  text-decoration: underline;
}
.item-numbers {
  position: absolute;
  top: 0;
  left: 0;
  /* font-size: 8px; */
  font-size: smaller;
  background-color: red;
  color: #fff;
  border-radius: 0 0 10px 0;
  padding: 2px 5px;
  /* -webkit-transform: scale(0.83333); */
}
</style>
