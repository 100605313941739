<template>
  <div class="max-width table">
    <CustomForm
      method="getAllMasterForFranchisee"
      ref="CustomForm"
      @ok="setData"
      :params="{ status: [6], is_detail: true }"
      :formItems="[
        { type: 'input', label: '订单编号', key: 'code', note: '按关键字搜索' },
        { type: 'time', label: '下单时间段', key: 'create_time' },
      ]"
    >
      <!-- 产品列表 -->
      <a-table
        :row-key="record => record.id_order_master"
        :data-source="data"
        :pagination="false"
        :expandIconColumnIndex="2"
        :columns="[
          { title: '订单编号', dataIndex: 'code', width: 140, align: 'center' },
          { title: '产品件数', dataIndex: 'cart', customRender: text => text.length + '件', align: 'center' },
          { title: '应付款', dataIndex: 'real_money', align: 'center' },
          { title: '已付款', dataIndex: 'pay_money', scopedSlots: { customRender: 'pay_money' }, align: 'center' },
          { title: '下单时间', dataIndex: 'create_time', align: 'center' },
          { title: '订单状态', dataIndex: 'status', scopedSlots: { customRender: 'status' }, align: 'center' },
          { title: '操作', scopedSlots: { customRender: 'action' }, align: 'center' },
        ]"
      >
        <span
          slot="pay_money"
          slot-scope="text, record"
          :class="record.status == 3 ? 'red' : record.status == 4 ? 'yellow' : ''"
        >{{ record.pay_money }}</span>
        <span slot="status" slot-scope="text, record">
          <span :style="'color:'+status[record.status].color">{{ status[record.status].label }}</span>
        </span>
        <span slot="action" slot-scope="text, record">
          <a @click="showDetails(record)" v-if="record.status===6" class="text black">详情</a>
          <a-divider type="vertical" v-if="record.status===6" />
          <a class="text black" v-if="record.status===6" @click="showContract(record)">合同</a>
        </span>
        <a-table
          size="small"
          slot="expandedRowRender"
          slot-scope="record"
          :row-key="record => record.id_order_cart"
          :data-source="record.cart"
          :pagination="false"
          :columns="[
            { title: '申购单号', dataIndex: 'code', width: 130 },
            { title: '产品', dataIndex: 'url', scopedSlots: { customRender: 'url' } },
            { title: '名称', dataIndex: 'name', scopedSlots: { customRender: 'name' } },
            { title: '供应商', dataIndex: 'company_name', scopedSlots: { customRender: 'company_name' } },
            { title: '数量', dataIndex: 'amount' },
            { title: '单位', dataIndex: 'product_unit', width: 80 },
            { title: '订单价格', dataIndex: 'order_money' },
            { title: '折后价格', dataIndex: 'real_money' },
          ]"
        >
          <span slot="url" slot-scope="text">
            <div class="image">
              <img mode="aspectFix" v-lazy="text" />
            </div>
          </span>
          <span slot="company_name" slot-scope="text, record">
            <div v-if="record.visible">{{text}}</div>
          </span>
          <span slot="name" slot-scope="text, record">
            <router-link :to="{ name: 'product', query: { id: record.id_product_info }}">
              <a class="theme" style="text-decoration: underline;">{{ text }}</a>
            </router-link>
          </span>
        </a-table>
      </a-table>
    </CustomForm>
    <!-- <a-modal v-model="isShowContract" title="销售清单" :showCancel="false" @ok="isShowContract = false">
      <a-form :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }" class="contract">
        <a-form-item label="总销售清单">
          <a @click="openContract" class="text">总销售清单</a>
        </a-form-item>
        <a-form-item label="供应商清单">
          <a-spin :spinning="isLoadingModal" size="small">
            <div v-for="s in modalData.suppliers" :key="s.id_user_supplier">
              <a @click="openContract(s)" class="text">销售清单 - {{ s.name }}</a>
            </div>
          </a-spin>
        </a-form-item>
      </a-form>
    </a-modal>-->
  </div>
</template>
<script>
import CustomForm from '@/component/CustomForm.vue'
export default {
  data() {
    return {
      isShowContract: false, // 是否显示弹框
      isLoadingModal: false, // 弹框是否加载中
      data: [], // 所有订单
      innerData: [],
      selectedData: [], // 选中的产品
      selectedDataKey: [], // 选中的产品id
      totalPrice: 0, // 总价
      headquarters: [], // 总部信息
      supplier_data: [], // 合同清单供应商信息
      isShowModal: false,
      isLoadTable: true,
      modalData: {
        headquarters: {},
        franchisee: {}
      }
    }
  },

  methods: {
    setData() {
      this.data.forEach((e) => {
        const unpay = e.real_money - e.pay_money
        e.unpay_money = unpay > 0 ? unpay.toFixed(2) : 0
      })
    },
    // showContract(record) {
    //   this.modalData = record
    //   this.supplier_data = record
    //   this.isLoadingModal = true
    //   this.isShowContract = true
    //   this.get('getSupplierInMasterForFranchisee', {
    //     id_order_master: record.id_order_master
    //   })
    //     .then((res) => {
    //       record.suppliers = res
    //       console.log(record.suppliers)
    //       this.modalData = record
    //       this.isLoadingModal = false
    //     })
    //     .catch((err) => {
    //       this.$message.error(err.msg)
    //       this.isShowContract = false
    //     })
    // },
    // 打开销售清单
    // openContract(supplier) {
    //   const routeData = this.$router.resolve({
    //     path: '/contract',
    //     query: {
    //       contract: this.supplier_data.code,
    //       supplier: supplier.id_user_supplier
    //     }
    //   })
    //   window.open(routeData.href, '_blank')
    // },
    showContract(order) {
      const routeData = this.$router.resolve({
        name: 'contract',
        query: { contract: order.code }
      })
      window.open(routeData.href, '_blank')
    },
    showDetails(record) {
      this.$router.push({
        name: 'details',
        query: { contract: record.code }
      })
    }
  },

  components: {
    CustomForm
  },

  props: ['status'],

  name: 'Cancel'
}
</script>

<style scoped>
.table {
  min-height: 600px;
  text-align: center;
}
.image {
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.image img {
  max-width: 100%;
  max-height: 60px;
}
.buy {
  width: 100%;
  height: 50px;
  border-bottom: 1px solid #e7e7e7;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.buy .button {
  text-align: center;
  background: var(--theme);
  color: #fff;
  width: 200px;
  height: 50px;
  margin-left: 30px;
  line-height: 50px;
  font-size: 22px;
  cursor: pointer;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}
.buy .button:hover {
  transform: scale(1.03);
}
.ant-form-item {
  margin: 0;
}
/* 文字下划线 */
.text:hover {
  text-decoration: underline;
}
</style>
