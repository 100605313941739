<template>
  <div class="max-width table">
    <CustomForm
      method="getAllCustomForFranchisee"
      ref="CustomForm"
      :params="{ status: [1, 2, 3, 4, 5, 6, 7], is_detail: true }"
      :formItems="[
        { type: 'input', label: '定制单号', key: 'code', note: '搜索申购单号' },
        { type: 'input', label: '产品名称', key: 'name', note: '搜索产品名称' },
        { type: 'input', label: '产品编号', key: 'sn_product_info', note: '搜索产品编号' },
        { type: 'select', label: '订单状态', key: 'status', note: '默认显示全部', default: 'custom', option: [
          { value: 'custom', label: '全部' },
          { value: 1, label: '等待处理' },
          { value: 2, label: '供应商拒绝' },
          { value: 3, label: '审核中' },
          { value: 4, label: '审核不通过' },
          { value: 5, label: '同意定制' },
          { value: 6, label: '已加入购物车' },
          { value: 7, label: '取消定制' }
        ] },
        { type: 'time', label: '下单时间段', key: 'create_time' },
      ]"
    >
      <!-- 产品列表 -->
      <a-table
        :row-key="record => record.id_order_custom"
        :data-source="data"
        :pagination="false"
        :expandIconColumnIndex="2"
        :columns="[
        { title: '定制单号', dataIndex: 'code', width: 130, align: 'center' },
        //{ title: '产品名称', dataIndex: 'name', scopedSlots: { customRender: 'name' }, align: 'center' },
        { title: '产品信息', dataIndex: 'cart[0].name', scopedSlots: { customRender: 'cart[0].name' }, align: 'center' },
        //{ title: '定制数量', dataIndex: 'amount', align: 'center' },
        //{ title: '单位', dataIndex: 'product_unit', align: 'center' },
        //{ title: '定制单价', dataIndex: 'price_custom', align: 'center' },
        { title: '需求', dataIndex: 'note', width: 300, align: 'center' },
        { title: '订单状态', dataIndex: 'status', scopedSlots: { customRender: 'status' }, align: 'center' },
        { title: '操作', scopedSlots: { customRender: 'action' }, align: 'center' },
      ]"
      >
        <!-- 产品信息 -->
        <span slot="cart[0].name" slot-scope="text, record">{{ text }}等{{record.cart.length}}件产品</span>
        <!-- 产品名称 -->
        <span slot="name" slot-scope="text, record">
          <router-link :to="{ name: 'product', query: { id: record.id_product_info }}">
            <a class="theme">{{ text }}</a>
          </router-link>
        </span>
        <!-- 订单状态 -->
        <span slot="status" slot-scope="text, record">{{ customStatus[record.status] }}</span>
        <!-- 按钮区域 -->
        <span slot="action" slot-scope="text, record">
          <!-- 取消 -->
          <!-- <a
            v-if="record.status === 1"
            type="link"
            style="padding: 0"
            class="text"
            @click="$confirm({ title: '取消', content: '您确定要取消定制吗', onOk: () => cancel(record) })"
          >取消</a>-->
          <!-- 单据拒绝 -->
          <a
            v-if="record.status === 2 || record.status === 4"
            type="link"
            style="padding: 0"
            @click="$info({
            title: '商家拒绝定制',
            content: record.content,
            maskClosable: true
          })"
            class="text"
          >查看原因</a>
          <!-- 加入购物车 -->
          <!-- <a
            v-if="record.status === 5"
            type="link"
            class="green text"
            style="padding: 0"
            @click="$confirm({
            title: '加入购物车',
            content: '定制订单只能加入一次。若从购物车中移除，则需重新申请定制。',
            onOk: () => joinCustomInCart(record)
          })"
          >加入购物车</a>-->
        </span>
        <a-table
          size="small"
          slot="expandedRowRender"
          slot-scope="record"
          :row-key="record => record.id_order_cart"
          :data-source="record.cart"
          :pagination="false"
          :columns="[
            { title: '申购单号', dataIndex: 'code', width: 130, align:'center' },
            { title: '产品', dataIndex: 'url', scopedSlots: { customRender: 'url' }, align:'center' },
            { title: '名称', dataIndex: 'name', scopedSlots: { customRender: 'name' }, align:'center' },
            { title: '供应商', dataIndex: 'company_name', scopedSlots: { customRender: 'company_name' }, align:'center' },
            { title: '数量', dataIndex: 'amount', align:'center' },
            { title: '定制价格', dataIndex: 'price_custom', align:'center' },
            { title: '单位', dataIndex: 'product_unit', width: 80, align:'center' },
            { title: '订单价格', dataIndex: 'order_money', align:'center' },
            { title: '折后价格', dataIndex: 'real_money', align:'center' },
            { title: '状态', dataIndex: 'status', scopedSlots: { customRender: 'status' }, width: 250, align:'center' },
          ]"
        >
          <span slot="url" slot-scope="text" style="display:flex;justify-content:center">
            <div class="image">
              <img mode="aspectFix" v-lazy="text" />
            </div>
          </span>
          <span slot="company_name" slot-scope="text, record">
            <div v-if="record.visible">{{text}}</div>
          </span>
          <span slot="name" slot-scope="text, record">
            <router-link :to="{ name: 'product', query: { id: record.id_product_info }}">
              <a class="theme" style="text-decoration: underline;">{{ text }}</a>
            </router-link>
          </span>
          <!-- 订单状态 -->
          <span slot="status" slot-scope="text, record" style="display:flex;flex-direction:column">
            <span :style="'color:'+status[record.status].color">
              {{ status[record.status].label }}
              <span
                v-if="record.inner_remaining_time"
                class="red"
              >{{ record.inner_remaining_time }}</span>
            </span>
          </span>
        </a-table>
      </a-table>
    </CustomForm>
  </div>
</template>
<script>
import CustomForm from '@/component/CustomForm.vue'
export default {
  data() {
    return {
      data: [], // 所有订单
      selectedData: [], // 选中的产品
      selectedDataKey: [], // 选中的产品id
      totalPrice: 0, // 总价
      modalData: {}, // 弹框数据
      isLoadTable: true,
      customStatus: [
        '已删除',
        '等待处理',
        '供应商拒绝',
        '审核中',
        '审核不通过',
        '同意定制',
        '已加入购物车',
        '取消定制'
      ]
    }
  },

  methods: {
    setData(res) {
      this.data = res
      this.isLoadTable = false
    },
    joinCustomInCart(record) {
      this.isLoadTable = true
      this.post('addCustomToCartForFranchisee', record)
        .then(() => {
          this.$message.success('加入成功')
          this.$refs.CustomForm.toPage(1)
          this.isLoadTable = false
        })
        .catch((err) => {
          this.$message.error(err.msg)
          this.isLoadTable = false
        })
    },
    cancel(record) {
      this.isLoadTable = true
      this.post('cancelCustomForFranchisee', record)
        .then(() => {
          this.$message.success('取消成功')
          this.$refs.CustomForm.toPage(1)
          this.isLoadTable = false
        })
        .catch((err) => {
          this.$message.error(err.msg)
          this.isLoadTable = false
        })
    }
  },

  components: {
    CustomForm
  },

  props: ['status'],

  name: 'Received'
}
</script>

<style scoped>
.table {
  min-height: 600px;
  text-align: center;
}
.image {
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.image img {
  max-width: 100%;
  max-height: 60px;
}
.buy {
  width: 100%;
  height: 50px;
  border-bottom: 1px solid #e7e7e7;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.buy .button {
  text-align: center;
  background: var(--theme);
  color: #fff;
  width: 200px;
  height: 50px;
  margin-left: 30px;
  line-height: 50px;
  font-size: 22px;
  cursor: pointer;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}
.buy .button:hover {
  transform: scale(1.03);
}
/* 和 upload 有关 */
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
/* 文字下划线 */
.text:hover {
  text-decoration: underline;
}
</style>
