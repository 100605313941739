<template>
  <div class="max-width table">
    <CustomForm
      method="getAllMasterForFranchisee"
      ref="CustomForm"
      @ok="setData"
      :params="{status:[2,3,4], is_detail: true }"
      :formItems="[
        { type: 'input', label: '订单编号', key: 'code', note: '按关键字搜索' },
        { type: 'time', label: '下单时间段', key: 'create_time' },
        { type: 'select', label: '订单状态', key: 'status', note: '默认显示全部', default:'2,3,4', option: [
          { value: '2,3,4', label: '全部' },
          { value: 2, label: '待支付' },
          { value: 3, label: '付款待确认' },
        ] },
      ]"
    >
      <!-- 产品列表 -->
      <a-table
        :row-key="record => record.id_order_master"
        :data-source="data"
        :pagination="false"
        :expandIconColumnIndex="2"
        :columns="[
          { title: '订单编号', dataIndex: 'code', width: 140, align: 'center' },
          { title: '产品信息', dataIndex: 'cart[0].url', scopedSlots: { customRender: 'url' }, width: 120, align: 'center' },
          //{ title: '产品信息', dataIndex: 'cart', scopedSlots: { customRender: 'information' } },
          //{ title: '产品信息', dataIndex: 'cart', customRender: text => text.length + '件' },
          { title: '订单金额', dataIndex: 'real_money', align: 'center' },
          //{ title: '已付款', dataIndex: 'pay_money', scopedSlots: { customRender: 'pay_money' } },
          { title: '订单时间', dataIndex: 'create_time', align: 'center' },
          { title: '定制订单', dataIndex: 'is_custom', scopedSlots: { customRender: 'is_custom' }, align: 'center' },
          { title: '订单状态', dataIndex: 'status', scopedSlots: { customRender: 'status' },  width: 250, align: 'center'  },
          { title: '操作', scopedSlots: { customRender: 'action' }, align: 'center' },
        ]"
      >
        <span slot="is_custom" slot-scope="text">{{text===1?'是':'否'}}</span>
        <span slot="url" slot-scope="text, record">
          <div class="image">
            <span class="item-numbers">共{{record.cart.length}}件产品</span>
            <img mode="aspectFix" v-lazy="text" />
          </div>
          <router-link :to="{ name: 'product', query: { id: record.id_product_info }}">
            <a class="theme">{{ record.cart[0].name }}</a>
          </router-link>等
          <!-- <span>{{record.cart[0].name}}等</span> -->
        </span>
        <!-- <span slot="information" slot-scope="text, record">
          <span>{{record.cart[0].name}}</span>
          <br />
          <span>等{{record.cart.length}}件</span>
          <br />
          <span>￥{{ record.real_money }}</span>
        </span>-->
        <span
          slot="pay_money"
          slot-scope="text, record"
          :class="record.status == 3 ? 'red' : record.status == 4 ? 'yellow' : ''"
        >{{ record.pay_money }}</span>
        <span slot="status" slot-scope="text, record" style="display:flex;flex-direction:column">
          <span :style="'color:'+status[record.status].color">{{ status[record.status].label }}</span>
          <span v-if="record.status === 3" class="black">{{ record.remaining_time }}</span>
          <span
            v-if="record.status > 1 && record.status < 5 && record.status !==3 && record.status !==4"
            class="black"
          >{{ record.remaining_time }}</span>
          <span
            v-if="record.status === 4  || record.status === 5"
            class="red"
          >{{ record.remaining_time }}</span>
        </span>
        <span slot="action" slot-scope="text, record">
          <!-- 收付款信息 -->
          <a
            class="text red"
            v-if="record.status > 1 && record.status < 5 && record.unpay_money!==0"
            @click="showModal(record)"
          >付款</a>
          <!-- <a-divider type="vertical" v-if="record.status === 2 || record.status === 4" />
          <a
            class="black text"
            v-if="record.status === 2 || record.status === 4"
            @click="showPayUrl(record)"
          >上传凭证</a>-->
          <a-divider type="vertical" v-if="record.unpay_money!==0" />
          <!-- 订货清单 -->
          <a class="black text" @click="showDetails(record)">详情</a>
          <a-divider type="vertical" />
          <a
            class="black text"
            v-if="record.status > 1 && record.status < 5"
            @click="showContract(record)"
          >合同</a>
          <a-divider type="vertical" v-if="record.status === 2" />
          <a-popover trigger="hover">
            <div slot="content" style="display:flex;flex-direction:column">
              <a
                class="text"
                @click="modalData=record;isShowModify=true"
                v-if="record.is_custom === 0"
              >撤回修改</a>
              <a-popconfirm
                title="您确定要取消该订单吗？"
                okType="danger"
                @confirm="cancel(record.id_order_master)"
              >
                <a v-if="record.status === 2" class="text">取消订单</a>
              </a-popconfirm>
            </div>
            <a v-if="record.status === 2" class="text">更多</a>
          </a-popover>
          <!-- 取消订单 -->
          <!-- <a-popconfirm
            title="您确定要取消该订单吗？"
            okType="danger"
            @confirm="cancel(record.id_order_master)"
          >
            <a v-if="record.status === 2" class="text">取消订单</a>
          </a-popconfirm>-->
          <a-divider type="vertical" v-if="record.status ===3" />
          <!-- 确认付款额 -->
          <a-popconfirm
            title="已付款金额核对正确，进入下一流程。"
            okType="primary"
            @confirm="confirm(record.id_order_master)"
          >
            <a v-if="record.status === 3" class="red" size="small">付款待确认</a>
          </a-popconfirm>
          <!-- <a-divider type="vertical" v-if="record.status === 2 || record.status === 4" /> -->
          <p style="text-align:left;margin-left:80px">
            <a
              class="black text"
              v-if="record.status === 2 || record.status === 4"
              @click="showPayUrl(record)"
            >上传凭证</a>
          </p>
        </span>
        <a-table
          size="small"
          slot="expandedRowRender"
          slot-scope="record"
          :row-key="record => record.id_order_cart"
          :data-source="record.cart"
          :pagination="false"
          :columns="[
            { title: '申购单号', dataIndex: 'code', width: 130, align:'center' },
            { title: '产品', dataIndex: 'url', scopedSlots: { customRender: 'url' }, align:'center' },
            { title: '名称', dataIndex: 'name', scopedSlots: { customRender: 'name' }, align:'center' },
            { title: '供应商', dataIndex: 'company_name', scopedSlots: { customRender: 'company_name' }, align:'center' },
            { title: '数量', dataIndex: 'amount', align:'center' },
            { title: '单位', dataIndex: 'product_unit', width: 80, align:'center' },
            { title: '订单价格', dataIndex: 'order_money', align:'center' },
            { title: '折后价格', dataIndex: 'real_money', align:'center' },
            { title: '状态', dataIndex: 'status', scopedSlots: { customRender: 'status' }, width: 250, align:'center' },
          ]"
        >
          <span slot="url" slot-scope="text" style="display:flex;justify-content:center">
            <div class="image">
              <img mode="aspectFix" v-lazy="text" />
            </div>
          </span>
          <span slot="company_name" slot-scope="text, record">
            <div v-if="record.visible">{{text}}</div>
          </span>
          <span slot="name" slot-scope="text, record">
            <router-link :to="{ name: 'product', query: { id: record.id_product_info }}">
              <a class="theme" style="text-decoration: underline;">{{ text }}</a>
            </router-link>
          </span>
          <!-- 订单状态 -->
          <span slot="status" slot-scope="text, record" style="display:flex;flex-direction:column">
            <span :style="'color:'+status[record.status].color">
              {{ status[record.status].label }}
              <span
                v-if="record.inner_remaining_time"
                class="red"
              >{{ record.inner_remaining_time }}</span>
            </span>
          </span>
        </a-table>
      </a-table>
    </CustomForm>
    <a-modal v-model="isShowModify" title="撤回修改" :showCancel="false" @ok="Modify">
      <a-form-item
        :label="item.name+'(装箱数'+item.num_boxing+')'"
        v-for="item in modalData.cart"
        :key="item.id_order_cart"
      >
        <a-input-number
          :min="0"
          style="width: 100%"
          placeholder="您希望的修改数量（大于等于0且为装箱数倍数）"
          :step="item.num_boxing"
          v-model="item.amount"
        />
      </a-form-item>
    </a-modal>
    <!-- <a-modal v-model="isShowContract" title="销售清单" :showCancel="false" @ok="isShowContract = false">
      <a-form :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }" class="contract">
        <a-form-item label="总销售清单">
          <a @click="openContract" class="text">总销售清单</a>
        </a-form-item>
        <a-form-item label="供应商清单">
          <a-spin :spinning="isLoadingModal" size="small">
            <div v-for="s in modalData.suppliers" :key="s.id_user_supplier">
              <a @click="openContract(s)" class="text">销售清单 - {{ s.name }}</a>
            </div>
          </a-spin>
        </a-form-item>
      </a-form>
    </a-modal>-->
    <a-modal v-model="isShowModal" title="收付款信息" :showCancel="false" @ok="isShowModal = false">
      <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 18 }">
        <a-alert description="请及时向收款方转账付款，超时订单可能被平台取消。" type="warning" show-icon />
        <a-form-item label="待付金额" style="margin: 20px 0 0 ">
          <span style="color: #e6a23c">￥{{ modalData.unpay_money }}</span>
          <!-- <span style="color: #909399">（ 应付款￥{{ modalData.real_money }} ）</span> -->
        </a-form-item>
        <!-- <a-form-item label="收款方">{{ modalData.headquarters.company_name }}</a-form-item> -->
        <a-form-item label="收款户名">{{ modalData.headquarters.bank_account }}</a-form-item>
        <a-form-item label="开户行">{{ modalData.headquarters.bank_name }}</a-form-item>
        <a-form-item label="账号">{{ modalData.headquarters.bank_number }}</a-form-item>
        <!-- <a-form-item label="付款方" style="margin: 20px 0 0">{{ modalData.franchisee.company_name }}</a-form-item> -->
        <!-- <a-form-item label="付款户名">{{ modalData.franchisee.bank_account }}</a-form-item> -->
        <!-- <a-form-item label="开户行及账号">{{ modalData.franchisee.bank_number }}</a-form-item> -->
      </a-form>
    </a-modal>
    <a-modal
      v-model="isShowPayUrl"
      title="上传付款凭证"
      :showCancel="false"
      @ok="confirmShowPayUrl"
      @cancel="close()"
    >
      <a-alert description="上传凭证不支持删除（最多9张）。" type="warning" show-icon />
      <a-form-item label="付款凭证">
        <div class="clearfix">
          <a-upload
            :action="$host + 'uploadFile'"
            list-type="picture-card"
            :file-list="fileList"
            :beforeUpload="imageAdd"
            :remove="imageRemove"
            @preview="imagePreview"
          >
            <div v-if="fileList.length < 9">
              <a-icon type="plus" />
              <div class="ant-upload-text">上传凭证</div>
            </div>
          </a-upload>
          <a-modal :visible="previewVisible" :footer="null" @cancel="imageCancel">
            <img alt="example" style="width: 100%" :src="previewImage" />
          </a-modal>
        </div>
      </a-form-item>
    </a-modal>
  </div>
</template>
<script>
import CustomForm from '@/component/CustomForm.vue'
var images = [] // 存储上传的图片
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })
}

export default {
  data() {
    return {
      // isShowContract: false, // 是否显示弹框
      isLoadingModal: false, // 弹框是否加载中
      headquarters: [], // 存总部信息，用于弹框显示
      modalData: {
        franchisee: {},
        headquarters: {}
      },
      data: [], // 所有订单
      innerData: [],
      selectedData: [], // 选中的产品
      selectedDataKey: [], // 选中的产品id
      totalPrice: 0, // 总价
      supplier_data: [], // 合同清单供应商信息
      isShowModal: false,
      // isShowFirstOrder: false, // 是否弹出待付款模态框
      fileList: [],
      isShowPayUrl: false, // 是否显示付款凭证模态框
      isShowModify: false, // 是否显示撤回修改模态框
      previewImage: '',
      previewVisible: false,
      flag_for_payurl: 0 // 判断是否上传了付款凭证
    }
  },

  methods: {
    // 关于上传凭证图片部分
    async imagePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj)
      }
      this.previewImage = file.url || file.preview
      this.previewVisible = true
    },
    imageCancel() {
      this.previewVisible = false
    },
    imageAdd(file) {
      images.push(file)
      const files = [file]
      this.spinning = true
      this.upload('uploadFile', files)
        .then((res) => {
          this.post('payMasterForFranchisee', {
            pay_url: res[0],
            id_order_master: this.modalData.id_order_master
          })
            .then(() => {
              this.spinning = false
              this.$message.success('上传成功')
              this.flag_for_payurl = 1
            })
            .catch((err) => {
              this.spinning = false
              this.$message.success(err.msg)
            })
          const obj = {
            uid: this.fileList.length.toString(),
            name: this.fileList.length.toString(),
            status: 'done',
            url: res[0]
          }
          this.fileList.push(obj)
        })
        .catch((err) => {
          this.spinning = false
          this.$message.error(err.msg)
        })
      return false
    },
    imageRemove() {
      this.$message.warn('不支持删除')
    },
    // setData
    setData() {
      this.data.forEach((e) => {
        const unpay = e.real_money - e.pay_money
        e.unpay_money = unpay > 0 ? unpay.toFixed(2) : 0
        const deltaTime =
          new Date().getTime() - new Date(e.create_time).getTime()
        const day = Math.floor(deltaTime / 1000 / 60 / 60 / 24)
        const hour = Math.floor(deltaTime / 1000 / 60 / 60) % 24
        var remainingDay
        var remainingHour
        if (e.status === 3) {
          remainingDay = day > 3 ? '' : 2 - day + '天'
          remainingHour = day > 3 ? 0 : 23 - hour
        } else {
          remainingDay = day > 14 ? '' : 14 - day + '天'
          remainingHour = day > 14 ? 0 : 23 - hour
        }
        // remainingDay = day > 14 ? '' : 14 - day + '天'
        // remainingHour = day > 14 ? 0 : 23 - hour
        e.remaining_time = `剩余${remainingDay}${remainingHour}时`
        if ((e.status === 4 || e.status === 5) && e.cart) {
          e.cart.forEach((i) => {
            const deltaTime =
              new Date().getTime() - new Date(i.create_time).getTime()
            const day = Math.floor(deltaTime / 1000 / 60 / 60 / 24)
            const hour = Math.floor(deltaTime / 1000 / 60 / 60) % 24
            const innerremainingDay =
              day > i.supply_cycle - 1 ? '' : i.supply_cycle - 1 - day + '天'
            const innerremainingHour = day > i.supply_cycle - 1 ? 0 : 23 - hour
            i.inner_remaining_time = `（剩余${innerremainingDay}${innerremainingHour}时）`
          })
        }
      })
      // 如果从购物车页面跳转过来，默认弹出第一条订单信息
      // if (this.isShowFirstOrder) {
      //   this.isShowFirstOrder = false
      //   this.showModal(this.data[0])
      // }
    },
    cancel(id) {
      this.$refs.CustomForm.isLoadingTable = true
      this.post('cancelMasterForFranchisee', {
        id_order_master: id
      })
        .then((res) => {
          this.$message.success('订单已取消')
          this.$refs.CustomForm.toPage(1)
          this.$refs.CustomForm.isLoadingTable = false
        })
        .catch((err) => {
          this.$message.error(err.msg)
          this.$refs.CustomForm.isLoadingTable = false
        })
    },
    confirm(id) {
      this.$refs.CustomForm.isLoadingTable = true
      this.post('confirmMasterForFranchisee', {
        id_order_master: id
      })
        .then((res) => {
          this.$message.success('金额已确认')
          this.$refs.CustomForm.toPage(1)
          this.$refs.CustomForm.isLoadingTable = false
        })
        .catch((err) => {
          this.$message.error(err.msg)
          this.$refs.CustomForm.isLoadingTable = false
        })
    },
    // 撤回修改确认
    Modify() {
      this.$refs.CustomForm.isLoadingTable = true
      var amount = []
      var id = []
      this.modalData.cart.forEach((e) => {
        amount.push(e.amount)
        id.push(e.id_order_cart)
      })
      this.post('modifyMasterForFranchisee', {
        id_order_cart: id,
        amount: amount
      })
        .then(() => {
          this.$message.success('订单已修改')
          this.isShowModify = false
          this.$refs.CustomForm.toPage(1)
          this.$refs.CustomForm.isLoadingTable = false
        })
        .catch((err) => {
          this.$message.error(err.msg)
        })
    },
    showModal(record) {
      record.franchisee = this.getUser()
      this.modalData = record
      this.isShowModal = true
    },
    // 付款凭证
    showPayUrl(record) {
      this.isShowPayUrl = true
      this.modalData = record
      const list = []
      for (let i = 0; i < this.modalData.pay_url.length; i++) {
        const obj = {
          uid: (i + 18).toString(),
          name: i.toString(),
          status: 'done',
          url: this.modalData.pay_url[i]
        }
        list.push(obj)
      }
      this.fileList = list
    },
    confirmShowPayUrl() {
      this.isShowPayUrl = false
      this.$refs.CustomForm.toPage(1)
    },
    showDetails(record) {
      this.$router.push({
        name: 'details',
        query: { contract: record.code }
      })
    },
    // showContract(record) {
    //   this.modalData = record
    //   this.supplier_data = record
    //   this.isLoadingModal = true
    //   this.isShowContract = true
    //   this.get('getSupplierInMasterForFranchisee', {
    //     id_order_master: record.id_order_master
    //   })
    //     .then((res) => {
    //       record.suppliers = res
    //       console.log(record.suppliers)
    //       this.modalData = record
    //       this.isLoadingModal = false
    //     })
    //     .catch((err) => {
    //       this.$message.error(err.msg)
    //       this.isShowContract = false
    //     })
    // },
    // 打开销售清单
    // openContract(supplier) {
    //   const routeData = this.$router.resolve({
    //     path: '/contract',
    //     query: {
    //       contract: this.supplier_data.code,
    //       supplier: supplier.id_user_supplier
    //     }
    //   })
    //   window.open(routeData.href, '_blank')
    // },
    showContract(order) {
      const routeData = this.$router.resolve({
        name: 'contract',
        query: { contract: order.code }
      })
      window.open(routeData.href, '_blank')
    },

    // 关闭付款凭证
    close() {
      if (this.flag_for_payurl) {
        this.$router.replace({ name: 'index', params: { forceStop: true } })
        setTimeout(() => {
          this.$router.replace({
            name: 'orders'
          })
        }, 10)
        setTimeout(() => {
          this.disabled = false
        }, 500)
      }
      this.flag_for_payurl = 0
    }
  },
  mounted() {
    // if (this.$route.params.unpay === 1) {
    //   this.isShowFirstOrder = true
    // }
  },

  components: {
    CustomForm
  },

  props: ['status'],

  name: 'Unpaid'
}
</script>

<style scoped>
.table {
  min-height: 600px;
  text-align: center;
}
.image {
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
}
.image img {
  width: 100%;
  /* height: 100%; */
}
.buy {
  width: 100%;
  height: 50px;
  border-bottom: 1px solid #e7e7e7;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.buy .button {
  text-align: center;
  background: var(--theme);
  color: #fff;
  width: 200px;
  height: 50px;
  margin-left: 30px;
  line-height: 50px;
  font-size: 22px;
  cursor: pointer;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}
.buy .button:hover {
  transform: scale(1.03);
}
.ant-form-item {
  margin: 0;
}
/* 文字下划线 */
.text:hover {
  text-decoration: underline;
}
.item-numbers {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 8px;
  background-color: red;
  color: #fff;
  border-radius: 0 0 10px 0;
  padding: 2px 5px;
}
</style>
