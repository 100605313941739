var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"max-width table"},[_c('CustomForm',{ref:"CustomForm",attrs:{"method":"getAllCustomForFranchisee","params":{ status: [1, 2, 3, 4, 5, 6, 7], is_detail: true },"formItems":[
      { type: 'input', label: '定制单号', key: 'code', note: '搜索申购单号' },
      { type: 'input', label: '产品名称', key: 'name', note: '搜索产品名称' },
      { type: 'input', label: '产品编号', key: 'sn_product_info', note: '搜索产品编号' },
      { type: 'select', label: '订单状态', key: 'status', note: '默认显示全部', default: 'custom', option: [
        { value: 'custom', label: '全部' },
        { value: 1, label: '等待处理' },
        { value: 2, label: '供应商拒绝' },
        { value: 3, label: '审核中' },
        { value: 4, label: '审核不通过' },
        { value: 5, label: '同意定制' },
        { value: 6, label: '已加入购物车' },
        { value: 7, label: '取消定制' }
      ] },
      { type: 'time', label: '下单时间段', key: 'create_time' } ]}},[_c('a-table',{attrs:{"row-key":function (record) { return record.id_order_custom; },"data-source":_vm.data,"pagination":false,"expandIconColumnIndex":2,"columns":[
      { title: '定制单号', dataIndex: 'code', width: 130, align: 'center' },
      //{ title: '产品名称', dataIndex: 'name', scopedSlots: { customRender: 'name' }, align: 'center' },
      { title: '产品信息', dataIndex: 'cart[0].name', scopedSlots: { customRender: 'cart[0].name' }, align: 'center' },
      //{ title: '定制数量', dataIndex: 'amount', align: 'center' },
      //{ title: '单位', dataIndex: 'product_unit', align: 'center' },
      //{ title: '定制单价', dataIndex: 'price_custom', align: 'center' },
      { title: '需求', dataIndex: 'note', width: 300, align: 'center' },
      { title: '订单状态', dataIndex: 'status', scopedSlots: { customRender: 'status' }, align: 'center' },
      { title: '操作', scopedSlots: { customRender: 'action' }, align: 'center' } ]},scopedSlots:_vm._u([{key:"cart[0].name",fn:function(text, record){return _c('span',{},[_vm._v(_vm._s(text)+"等"+_vm._s(record.cart.length)+"件产品")])}},{key:"name",fn:function(text, record){return _c('span',{},[_c('router-link',{attrs:{"to":{ name: 'product', query: { id: record.id_product_info }}}},[_c('a',{staticClass:"theme"},[_vm._v(_vm._s(text))])])],1)}},{key:"status",fn:function(text, record){return _c('span',{},[_vm._v(_vm._s(_vm.customStatus[record.status]))])}},{key:"action",fn:function(text, record){return _c('span',{},[(record.status === 2 || record.status === 4)?_c('a',{staticClass:"text",staticStyle:{"padding":"0"},attrs:{"type":"link"},on:{"click":function($event){return _vm.$info({
          title: '商家拒绝定制',
          content: record.content,
          maskClosable: true
        })}}},[_vm._v("查看原因")]):_vm._e()])}},{key:"expandedRowRender",fn:function(record){return _c('a-table',{attrs:{"size":"small","row-key":function (record) { return record.id_order_cart; },"data-source":record.cart,"pagination":false,"columns":[
          { title: '申购单号', dataIndex: 'code', width: 130, align:'center' },
          { title: '产品', dataIndex: 'url', scopedSlots: { customRender: 'url' }, align:'center' },
          { title: '名称', dataIndex: 'name', scopedSlots: { customRender: 'name' }, align:'center' },
          { title: '供应商', dataIndex: 'company_name', scopedSlots: { customRender: 'company_name' }, align:'center' },
          { title: '数量', dataIndex: 'amount', align:'center' },
          { title: '定制价格', dataIndex: 'price_custom', align:'center' },
          { title: '单位', dataIndex: 'product_unit', width: 80, align:'center' },
          { title: '订单价格', dataIndex: 'order_money', align:'center' },
          { title: '折后价格', dataIndex: 'real_money', align:'center' },
          { title: '状态', dataIndex: 'status', scopedSlots: { customRender: 'status' }, width: 250, align:'center' } ]},scopedSlots:_vm._u([{key:"url",fn:function(text){return _c('span',{staticStyle:{"display":"flex","justify-content":"center"}},[_c('div',{staticClass:"image"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(text),expression:"text"}],attrs:{"mode":"aspectFix"}})])])}},{key:"company_name",fn:function(text, record){return _c('span',{},[(record.visible)?_c('div',[_vm._v(_vm._s(text))]):_vm._e()])}},{key:"name",fn:function(text, record){return _c('span',{},[_c('router-link',{attrs:{"to":{ name: 'product', query: { id: record.id_product_info }}}},[_c('a',{staticClass:"theme",staticStyle:{"text-decoration":"underline"}},[_vm._v(_vm._s(text))])])],1)}},{key:"status",fn:function(text, record){return _c('span',{staticStyle:{"display":"flex","flex-direction":"column"}},[_c('span',{style:('color:'+_vm.status[record.status].color)},[_vm._v(" "+_vm._s(_vm.status[record.status].label)+" "),(record.inner_remaining_time)?_c('span',{staticClass:"red"},[_vm._v(_vm._s(record.inner_remaining_time))]):_vm._e()])])}}])})}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }