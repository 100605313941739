<template>
  <div class="max-width table">
    <CustomForm
      method="getAllMasterForFranchisee"
      ref="CustomForm"
      @ok="setData"
      :params="{ status: [5, 7, 8, 9, 10, 11, 12, 13, 14], is_detail: true }"
      :formItems="[
        { type: 'input', label: '申购单号', key: 'code', note: '搜索申购单号' },
        { type: 'input', label: '产品名称', key: 'name', note: '搜索产品名称' },
        { type: 'input', label: '产品编号', key: 'sn_product_info', note: '搜索产品编号' },
        { type: 'select', label: '订单状态', key: 'status', note: '默认显示全部', default: 'received', option: [
          { value: 'received', label: '全部' },
          { value: 5, label: '待发货' },
          { value: 7, label: '待收货' },
          { value: 8, label: '已收货' },
          { value: 9, label: '退货申请中' }, // 9 蓝色
          { value: 10, label: '退货待发货' }, // 10 红色
          { value: 11, label: '退货中' }, // 11 黑色
          { value: 12, label: '待退款' }, // 12 黄色
          { value: 13, label: '已退货' }, // 13 绿色
          { value: 14, label: '退货取消' } // 14 灰色
        ] },
        { type: 'time', label: '下单时间段', key: 'create_time' },
      ]"
    >
      <a-alert type="info">
        <template slot="description">
          <span style="color: red;font-size:16px">温馨提示：供应商发货 30 天后系统自动确认收货。</span>
        </template>
      </a-alert>
      <!-- 产品列表 -->
      <a-table
        :row-key="record => record.id_order_master"
        :data-source="data"
        :pagination="false"
        :expandIconColumnIndex="2"
        :columns="[
        { title: '订单编号', dataIndex: 'code', width: 90, align: 'center',width:180 },
        { title: '产品信息', dataIndex: 'cart[0].url', scopedSlots: { customRender: 'url' }, width: 120, align: 'center' },
        //{ title: '名称', dataIndex: 'name', scopedSlots: { customRender: 'name' }, width: 120},
        //{ title: '数量', dataIndex: 'amount' },
        //{ title: '单位', dataIndex: 'product_unit' },
        { title: '订单金额', dataIndex: 'real_money', align: 'center' },
        { title: '发货时间', dataIndex: 'delivery_time', scopedSlots: { customRender: 'delivery_time' }, align: 'center'  },
        { title: '收货时间', dataIndex: 'receive_time', align: 'center' },
        { title: '订单状态', dataIndex: 'status', scopedSlots: { customRender: 'status' }, width:120, align: 'center'},
        //{ title: '物流公司', dataIndex: 'express_info1', scopedSlots: { customRender: 'delivery_company' }, align: 'center' },
        //{ title: '物流单号', dataIndex: 'express_info2', scopedSlots: { customRender: 'delivery_id' }, align: 'center' },
        //{ title: '物流电话', dataIndex: 'express_info3', scopedSlots: { customRender: 'delivery_phone' }, align: 'center' },
        { title: '操作', scopedSlots: { customRender: 'action' }, width: 110, align: 'center', width:450 },
      ]"
      >
        <span slot="url" slot-scope="text, record">
          <div class="image">
            <span class="item-numbers">共{{record.cart.length}}件产品</span>
            <img mode="aspectFix" v-lazy="text" />
          </div>
          <router-link :to="{ name: 'product', query: { id: record.id_product_info }}">
            <a class="theme">{{ record.name }}</a>
          </router-link>
        </span>
        <!-- <span slot="delivery_company" slot-scope="text, record">
          <span
            style="word-break:break-all;"
          >{{record.express_info.substring(0,record.express_info.indexOf('~!*'))}}</span>
        </span>
        <span slot="delivery_id" slot-scope="text, record">
          <span
            style="word-break:break-all;"
          >{{record.express_info.substring(record.express_info.substring(0,record.express_info.indexOf('~!*')).length+3,record.express_info.lastIndexOf('~!*'))}}</span>
        </span>
        <span slot="delivery_phone" slot-scope="text, record">
          <span
            style="word-break:break-all;"
          >{{record.express_info.substring(record.express_info.lastIndexOf('~!*')+3)}}</span>
        </span>-->
        <span slot="delivery_time" slot-scope="text, record">
          <span v-if="record.status === 5">
            <div v-if="record.estimated_delivery_time">预计：</div>
            {{ record.estimated_delivery_time }}
          </span>
          <span v-else>{{ text }}</span>
        </span>
        <!-- <span slot="name" slot-scope="text, record">
          <router-link :to="{ name: 'product', query: { id: record.id_product_info }}">
            <a class="theme">{{ text }}</a>
          </router-link>
        </span>-->
        <span slot="status" slot-scope="text, record" style="display:flex; flex-direction:column">
          <span :style="'color:'+status[record.status].color">{{ status[record.status].label }}</span>
          <span
            v-if="record.status === 4 || record.status === 5"
            class="red"
          >{{ record.remaining_time }}</span>
        </span>
        <!-- 各类按钮 -->
        <span slot="action" slot-scope="text, record">
          <!-- 查看物流 -->
          <a class="text" @click="modalData = record; modalName.logistics = true">查看物流</a>
          <a-divider
            type="vertical"
            v-if="record.status === 5 || record.status === 7 || record.status === 14 || record.status === 8"
          />
          <!-- 催发货 -->
          <a
            v-if="record.status === 5"
            :loading="isLoadButton[record.code]"
            @click="rushOrder(record)"
            class="text"
          >催发货</a>
          <!-- 确认收货 -->
          <a
            v-if="record.status === 7"
            class="green text"
            @click="confirmReceive(record.id_order_master)"
          >确认收货</a>
          <!-- 退货 -->
          <!-- <span v-if="record.invoice_franchisee_status == 1"> -->
          <a
            v-if="record.status === 8 || record.status === 14"
            @click="imageRemoveAll(); modalData = record; modalName.refund = true"
            class="text"
          >退货</a>
          <a-divider type="vertical" v-if="record.status === 8 || record.status === 14" />
          <!-- </span> -->
          <!-- 评价 -->
          <a
            v-if="record.status === 8 || record.status === 14"
            @click="imageRemoveAll(); showComment(record)"
            class="text"
          >评价</a>
          <!-- 退货发货 -->
          <!-- <a-button
          v-if="record.status === 10"
          class="yellow"
          type="link"
          @click="refundShip(record)"
          >退货发货</a-button>-->
        </span>
        <a-table
          size="small"
          slot="expandedRowRender"
          slot-scope="record"
          :row-key="(record,index) => index"
          :data-source="record.cart"
          :pagination="false"
          :columns="[
            { title: '申购单号', dataIndex: 'code', width: 130, align:'center' },
            { title: '产品', dataIndex: 'url', scopedSlots: { customRender: 'url' }, align:'center' },
            { title: '名称', dataIndex: 'name', scopedSlots: { customRender: 'name' }, align:'center' },
            { title: '供应商', dataIndex: 'company_name', scopedSlots: { customRender: 'company_name' }, align:'center' },
            { title: '数量', dataIndex: 'amount', align:'center' },
            { title: '单位', dataIndex: 'product_unit', width: 80, align:'center' },
            { title: '订单价格', dataIndex: 'order_money', align:'center' },
            { title: '折后价格', dataIndex: 'real_money', align:'center' },
          ]"
        >
          <span slot="url" slot-scope="text" style="display:flex;justify-content:center">
            <div class="image">
              <img mode="aspectFix" v-lazy="text" />
            </div>
          </span>
          <span slot="company_name" slot-scope="text, record">
            <div v-if="record.visible">{{text}}</div>
          </span>
          <span slot="name" slot-scope="text, record">
            <router-link :to="{ name: 'product', query: { id: record.id_product_info }}">
              <a class="theme" style="text-decoration: underline;">{{ text }}</a>
            </router-link>
          </span>
        </a-table>
      </a-table>
    </CustomForm>
    <!-- 查看物流弹框 -->
    <a-modal v-model="modalName.logistics" title="物流信息" @ok="modalName.logistics=false">
      <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 18 }">
        <a-form-item label="物流公司" style="margin: 20px 0">{{modalData.express_info_company}}</a-form-item>
        <a-form-item label="物流单号">{{modalData.express_info_id}}</a-form-item>
        <a-form-item label="物流电话">{{modalData.express_info_phone}}</a-form-item>
      </a-form>
    </a-modal>
    <!-- 提交评价 -->
    <a-modal
      v-model="modalName.comment"
      title="评价"
      okText="提交"
      @ok="comment"
      :footer="() => { if (isLoadComment || hasComment) return null }"
      :confirmLoading="isLoadComment"
      :okButtonProps="{ props: { loading: isLoadTable } }"
    >
      <a-skeleton active :loading="isLoadComment" :paragraph="{ rows: 8 }">
        <!-- 已有评论 -->
        <div v-if="hasComment" class="comment">
          <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 18 }">
            <a-form-item label="评分" style="margin: 20px 0" required>
              <a-rate v-model="modalData.rank" disabled allowHalf />
              <span class="ant-rate-text">{{ rateText[~~(modalData.rank - .5)] }}</span>
            </a-form-item>
            <a-form-item label="评价" required>
              <a-input type="textarea" disabled style="height: 100px" v-model="modalData.content" />
            </a-form-item>
            <a-form-item label="图片" v-if="modalData.url !== []">
              <img
                v-image-preview
                v-for="(url, index) in modalData.url"
                :key="url+index"
                :src="url"
              />
            </a-form-item>
          </a-form>
        </div>
        <!-- 新撰写评论 -->
        <a-spin :spinning="isSubmitComment" v-else>
          <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 18 }">
            <a-form-item label="评分" style="margin: 20px 0" required>
              <a-rate v-model="modalData.rank" :tooltips="rateText" :allowClear="false" allowHalf />
            </a-form-item>
            <a-form-item label="评价" required>
              <a-input
                type="textarea"
                style="height: 100px"
                placeholder="请输入对本次订单的评价"
                :max="255"
                v-model="modalData.content"
              />
            </a-form-item>
            <a-form-item label="图片">
              <a-upload
                :action="$host + 'uploadFile'"
                list-type="picture-card"
                :file-list="fileList"
                :beforeUpload="imageAdd"
                :remove="imageRemove"
                @preview="imagePreview"
                @change="imageChange"
              >
                <div v-if="fileList.length < 9">
                  <a-icon type="plus" />
                  <div class="ant-upload-text">上传</div>
                </div>
              </a-upload>
            </a-form-item>
          </a-form>
        </a-spin>
      </a-skeleton>
    </a-modal>
    <!-- 申请退货弹框 -->
    <a-modal
      v-model="modalName.refund"
      title="申请退货"
      okText="确认退货"
      @ok="refund"
      :okButtonProps="{ props: { loading: isLoadTable } }"
    >
      <a-table
        size="small"
        :row-key="(record,index) => modalData.cart.id_order_cart"
        :data-source="modalData.cart"
        :pagination="false"
        :columns="[
            { title: '产品名称', dataIndex: 'name', width: 130, align:'center' },
            { title: '订单总金额', dataIndex: 'price', scopedSlots: { customRender: 'price' }, align:'center' },
            { title: '订单总数量', dataIndex: 'amount',align:'center' },
            { title: '退货数量', dataIndex: 'refund_amount',align:'center', scopedSlots: { customRender: 'refund_amount' }, align:'center'  },
          ]"
      >
        <span slot="price" slot-scope="text, record">
          <div>{{(record.amount * record.price_settle).toFixed(2) }}</div>
        </span>
        <span slot="refund_amount" slot-scope="text, record">
          <a-input-number :min="0" :max="record.amount" v-model="record.refund_amount" />
        </span>
      </a-table>
      <a-form style="margin-top:24px">
        <a-form-item label="退货理由">
          <a-input type="text" placeholder="请输入退货理由" :max="255" v-model="modalData.refundReason" />
        </a-form-item>
        <a-form-item label="产品照片">
          <a-upload
            :action="$host + 'uploadFile'"
            list-type="picture-card"
            :file-list="fileList"
            :beforeUpload="imageAdd"
            :remove="imageRemove"
            @preview="imagePreview"
            @change="imageChange"
          >
            <div v-if="fileList.length < 9">
              <a-icon type="plus" />
              <div class="ant-upload-text">上传</div>
            </div>
          </a-upload>
        </a-form-item>
      </a-form>
    </a-modal>
    <!-- 图片预览 -->
    <a-modal :visible="previewVisible" :footer="null" @cancel="imageCancel">
      <img alt="example" style="width: 100%" :src="previewImage" />
    </a-modal>
  </div>
</template>
<script>
import CustomForm from '@/component/CustomForm.vue'
var images = [] // 存储上传的图片
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })
}

export default {
  data() {
    return {
      data: [], // 所有订单
      innerData: [],
      selectedData: [], // 选中的产品
      selectedDataKey: [], // 选中的产品id
      totalPrice: 0, // 总价
      modalData: {}, // 弹框数据
      modalName: this.hideModal(), // 弹框名称
      isLoadTable: false,
      isLoadButton: false, // 催发货的加载中标记
      isLoadComment: false, // 是否在加载评论
      isSubmitComment: false, // 是否在提交评论
      hasComment: false, // 是否已存在评论
      fileList: [], // 上传图片列表
      previewVisible: false, // upload 图片预览
      previewImage: '',
      rateText: ['糟糕', '失望', '一般', '还行', '满意']
    }
  },

  methods: {
    setData() {
      // this.data = res
      // console.log(this.data)
      this.isLoadTable = false
      this.data.forEach((e) => {
        const deltaTime =
          new Date().getTime() - new Date(e.create_time).getTime()
        const day = Math.floor(deltaTime / 1000 / 60 / 60 / 24)
        const hour = Math.floor(deltaTime / 1000 / 60 / 60) % 24
        var remainingDay
        var remainingHour
        if (e.status === 4 || e.status === 5) {
          remainingDay = day > 14 ? '' : 14 - day + '天'
          remainingHour = day > 14 ? 0 : 23 - hour
        }
        e.remaining_time = `剩余${remainingDay}${remainingHour}时`
        if (e.express_info.length) {
          e.express_info_company = e.express_info.substring(
            0,
            e.express_info.indexOf('~!*')
          )
          e.express_info_id = e.express_info.substring(
            e.express_info.substring(0, e.express_info.indexOf('~!*')).length +
              3,
            e.express_info.lastIndexOf('~!*')
          )
          e.express_info_phone = e.express_info.substring(
            e.express_info.lastIndexOf('~!*') + 3
          )
        }
      })
    },
    // 确认收货
    confirmReceive(id) {
      this.$confirm({
        title: '确认收货',
        content: '确认本订单已收货。',
        onOk: () => {
          this.isLoadTable = true
          this.post('receiptMasterForFranchisee', {
            id_order_master: id
          })
            .then(() => {
              this.$message.success('已确认收货')
              this.isLoadTable = false
              this.$refs.CustomForm.toPage(1)
            })
            .catch((err) => {
              this.$message.error(err.msg)
              this.isLoadTable = false
            })
        }
      })
    },
    // 查看评价
    showComment(record) {
      this.modalData = Object.assign({}, record)
      this.modalName.comment = true // 显示评论弹框
      this.isLoadComment = true // 显示骨架屏
      this.hasComment = false // 已存在评论
      this.get('getAllCommentForFranchisee', {
        id_order_master: record.id_order_master
      })
        .then((res) => {
          this.isLoadComment = false
          if (res.total) {
            this.hasComment = true
            this.modalData = res.data[0]
            this.modalData.rank /= 2
          }
        })
        .catch((err) => {
          this.hideModal()
          this.$message.error(err.msg)
          this.isLoadComment = false
        })
    },
    // 评价
    comment() {
      if (!this.modalData.rank) return this.$message.warn('请选择评分')
      if (!this.modalData.content) return this.$message.warn('请填写评价')
      this.isSubmitComment = true
      const post = (url) => {
        const data = {
          id_order_master: this.modalData.id_order_master,
          content: this.modalData.content,
          rank: this.modalData.rank * 2
        }
        if (url) data.url = url
        this.post('addCommentForFranchisee', data)
          .then(() => {
            this.$message.success('评价成功')
            this.isSubmitComment = false
            this.hideModal()
          })
          .catch((err) => {
            this.$message.error(err.msg)
            this.isSubmitComment = false
          })
      }
      if (images.length) {
        this.upload('uploadFile', images)
          .then((url) => {
            post(url)
          })
          .catch((err) => {
            this.$message.error(err.msg)
            this.isSubmitComment = false
          })
      } else {
        post()
      }
    },
    // 申请退货
    refund() {
      // console.log(this.modalData)
      if (!this.modalData.refundReason) {
        return this.$message.warn('请输入退货理由')
      }
      if (!images.length) {
        return this.$message.warn('请上传产品照片')
      }
      this.isLoadTable = true
      const cart = []
      const amount = []
      this.modalData.cart.forEach((e) => {
        cart.push(e.id_order_cart)
        amount.push(e.refund_amount)
      })
      this.upload('uploadFile', images)
        .then((url) => {
          this.post('addRefundForFranchisee', {
            url: url,
            id_order_cart: cart,
            refund_reason: this.modalData.refundReason,
            refund_amount: amount,
            id_order_master: this.modalData.id_order_master
          })
            .then(() => {
              this.$message.success('已提交退货申请，等待平台处理')
              this.$refs.CustomForm.toPage(1)
              this.isLoadTable = false
              this.hideModal()
            })
            .catch((err) => {
              this.$message.error(err.msg)
              this.isLoadTable = false
            })
        })
        .catch((err) => {
          this.$message.error(err.msg)
          this.isLoadTable = false
        })
    },
    // 催单
    rushOrder(record) {
      if (this.isLoadTable) return false
      this.isLoadTable = true
      this.post('rushMasterForFranchisee', {
        id_order_master: record.id_order_master
      })
        .then(() => {
          this.$message.success('催发货成功')
          this.isLoadTable = false
        })
        .catch((err) => {
          this.$message.error(err.msg)
          this.isLoadTable = false
        })
    },
    // 退货发货
    refundShip(record) {
      // console.log(this.$parent)
      this.$parent.activeTab = 9
    },
    hideModal() {
      return (this.modalName = {
        logistics: false, // 从查看物流
        refund: false, // 申请退货
        comment: false, // 评价
        express: false // 退货发货
      })
    },
    // 和图片上传有关
    async imagePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj)
      }
      this.previewImage = file.url || file.preview
      this.previewVisible = true
    },
    imageCancel() {
      this.previewVisible = false
    },
    imageChange({ fileList }) {
      this.fileList = fileList
    },
    imageAdd(file) {
      images.push(file)
      return false
    },
    imageRemove(file) {
      this.deleteFile(file, this.fileList)
      this.deleteFile(file, images)
      return false
    },
    deleteFile(file, res) {
      res.splice(res.indexOf(res.find((item) => item.uid === file.uid)), 1)
      if (res.length === 0) res = []
    },
    imageRemoveAll() {
      this.fileList = []
      images = []
    }
  },

  components: {
    CustomForm
  },

  props: ['status'],

  name: 'Received'
}
</script>

<style scoped>
.table {
  min-height: 600px;
  text-align: center;
}
.image {
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
}
.image img {
  width: 100%;
  /* max-height: 100%; */
}
.buy {
  width: 100%;
  height: 50px;
  border-bottom: 1px solid #e7e7e7;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.buy .button {
  text-align: center;
  background: var(--theme);
  color: #fff;
  width: 200px;
  height: 50px;
  margin-left: 30px;
  line-height: 50px;
  font-size: 22px;
  cursor: pointer;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}
.buy .button:hover {
  transform: scale(1.03);
}
.comment img {
  width: 100px;
  height: 100px;
  border-radius: 5px;
  margin: 0 10px 10px 0;
}
.comment img:nth-last-child(3) {
  margin-right: 0;
}
.ant-btn-link {
  padding: 0;
  margin-right: 15px;
}
.ant-btn span {
  margin: 0;
}
/* 和 upload 有关 */
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
/* 文字下划线 */
.text:hover {
  text-decoration: underline;
}
.item-numbers {
  position: absolute;
  top: 0;
  left: 0;
  /* font-size: 8px; */
  font-size: smaller;
  background-color: red;
  color: #fff;
  border-radius: 0 0 10px 0;
  padding: 2px 5px;
  /* -webkit-transform: scale(0.83333); */
}
</style>
