var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"max-width table"},[_c('CustomForm',{ref:"CustomForm",attrs:{"method":"getAllMasterForFranchisee","params":{ status: [6], is_detail: true },"formItems":[
      { type: 'input', label: '订单编号', key: 'code', note: '按关键字搜索' },
      { type: 'time', label: '下单时间段', key: 'create_time' } ]},on:{"ok":_vm.setData}},[_c('a-table',{attrs:{"row-key":function (record) { return record.id_order_master; },"data-source":_vm.data,"pagination":false,"expandIconColumnIndex":2,"columns":[
        { title: '订单编号', dataIndex: 'code', width: 140, align: 'center' },
        { title: '产品件数', dataIndex: 'cart', customRender: function (text) { return text.length + '件'; }, align: 'center' },
        { title: '应付款', dataIndex: 'real_money', align: 'center' },
        { title: '已付款', dataIndex: 'pay_money', scopedSlots: { customRender: 'pay_money' }, align: 'center' },
        { title: '下单时间', dataIndex: 'create_time', align: 'center' },
        { title: '订单状态', dataIndex: 'status', scopedSlots: { customRender: 'status' }, align: 'center' },
        { title: '操作', scopedSlots: { customRender: 'action' }, align: 'center' } ]},scopedSlots:_vm._u([{key:"pay_money",fn:function(text, record){return _c('span',{class:record.status == 3 ? 'red' : record.status == 4 ? 'yellow' : ''},[_vm._v(_vm._s(record.pay_money))])}},{key:"status",fn:function(text, record){return _c('span',{},[_c('span',{style:('color:'+_vm.status[record.status].color)},[_vm._v(_vm._s(_vm.status[record.status].label))])])}},{key:"action",fn:function(text, record){return _c('span',{},[(record.status===6)?_c('a',{staticClass:"text black",on:{"click":function($event){return _vm.showDetails(record)}}},[_vm._v("详情")]):_vm._e(),(record.status===6)?_c('a-divider',{attrs:{"type":"vertical"}}):_vm._e(),(record.status===6)?_c('a',{staticClass:"text black",on:{"click":function($event){return _vm.showContract(record)}}},[_vm._v("合同")]):_vm._e()],1)}},{key:"expandedRowRender",fn:function(record){return _c('a-table',{attrs:{"size":"small","row-key":function (record) { return record.id_order_cart; },"data-source":record.cart,"pagination":false,"columns":[
          { title: '申购单号', dataIndex: 'code', width: 130 },
          { title: '产品', dataIndex: 'url', scopedSlots: { customRender: 'url' } },
          { title: '名称', dataIndex: 'name', scopedSlots: { customRender: 'name' } },
          { title: '供应商', dataIndex: 'company_name', scopedSlots: { customRender: 'company_name' } },
          { title: '数量', dataIndex: 'amount' },
          { title: '单位', dataIndex: 'product_unit', width: 80 },
          { title: '订单价格', dataIndex: 'order_money' },
          { title: '折后价格', dataIndex: 'real_money' } ]},scopedSlots:_vm._u([{key:"url",fn:function(text){return _c('span',{},[_c('div',{staticClass:"image"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(text),expression:"text"}],attrs:{"mode":"aspectFix"}})])])}},{key:"company_name",fn:function(text, record){return _c('span',{},[(record.visible)?_c('div',[_vm._v(_vm._s(text))]):_vm._e()])}},{key:"name",fn:function(text, record){return _c('span',{},[_c('router-link',{attrs:{"to":{ name: 'product', query: { id: record.id_product_info }}}},[_c('a',{staticClass:"theme",staticStyle:{"text-decoration":"underline"}},[_vm._v(_vm._s(text))])])],1)}}])})}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }