<template>
  <div class="max-width table">
    <CustomForm
      method="getAllMasterForFranchisee"
      ref="CustomForm"
      @ok="setData"
      :params="{ is_detail: true }"
      :formItems="[
        { type: 'input', label: '订单编号', key: 'code', note: '按关键字搜索' },
        { type: 'select', label: '订单状态', key: 'status', note: '默认显示全部', option: [
          { value: 2, label: '待支付' },
          { value: 3, label: '付款待确认' },
          { value: 5, label: '已支付' },
          { value: 6, label: '已取消' }
        ] },
        { type: 'time', label: '下单时间段', key: 'create_time' },
      ]"
    >
      <!-- 产品列表 -->
      <a-table
        :row-key="record => record.id_order_master"
        :data-source="data"
        :pagination="false"
        :expandIconColumnIndex="2"
        :columns="[
          { title: '订单编号', dataIndex: 'code', width: 140, align: 'center' },
          { title: '订单产品', dataIndex: 'cart[0].url', scopedSlots: { customRender: 'url' }, width: 120, align: 'center' },
          { title: '产品件数', dataIndex: 'cart', customRender: text => text.length + '件', align: 'center' },
          { title: '应付款', dataIndex: 'real_money', align: 'center' },
          { title: '已付款', dataIndex: 'pay_money', scopedSlots: { customRender: 'pay_money' }, align: 'center' },
          { title: '下单时间', dataIndex: 'create_time', align: 'center' },
          { title: '订单状态', dataIndex: 'status', scopedSlots: { customRender: 'status' }, align: 'center' },
          { title: '操作', scopedSlots: { customRender: 'action' }, align: 'center' },
        ]"
      >
        <span slot="url" slot-scope="text" style="display:flex;justify-content:center">
          <div class="image">
            <img mode="aspectFix" v-lazy="text" />
          </div>
        </span>
        <span
          slot="pay_money"
          slot-scope="text, record"
          :class="record.status === 3 ? 'red' : record.status === 4 ? 'yellow' : ''"
        >{{ record.pay_money }}</span>
        <!-- 订单状态 -->
        <span slot="status" slot-scope="text, record" :style="'color:'+status[record.status].color">
          <span style="display:flex; flex-direction:column">
            {{ record.status === 5 ? '已支付' : record.status === 6 ? '已取消' : status[record.status].label }}
            <span
              v-if="record.status > 1 && record.status < 5"
              class="black"
            >{{ record.remaining_time }}</span>
          </span>
        </span>
        <span slot="action" slot-scope="text, record">
          <!-- 订货清单 -->
          <a v-if="record.status !== 6" @click="showContract(record)" class="text">合同</a>
          <a-divider type="vertical" v-if="record.status < 5" />
          <!-- 收付款信息 -->
          <a
            v-if="record.status > 1 && record.status < 5"
            @click="showModal(record)"
            class="text"
          >查看收付款</a>
          <a-divider type="vertical" v-if="record.status === 2 || record.status === 3" />
          <!-- 取消订单 -->
          <a-popconfirm
            title="您确定要取消该订单吗？"
            okType="danger"
            @confirm="cancel(record.id_order_master)"
          >
            <a v-if="record.status === 2" class="text">取消订单</a>
          </a-popconfirm>
          <!-- 确认付款额 -->
          <a-popconfirm
            title="已付款金额核对正确，进入下一流程。"
            okType="primary"
            @confirm="confirm(record.id_order_master)"
          >
            <a v-if="record.status === 3" class="red text" size="small">确认付款额</a>
          </a-popconfirm>
        </span>
        <a-table
          size="small"
          slot="expandedRowRender"
          slot-scope="record"
          :row-key="record => record.id_order_cart"
          :data-source="record.cart"
          :pagination="false"
          :columns="[
            { title: '申购单号', dataIndex: 'code', width: 130, align: 'center' },
            { title: '产品', dataIndex: 'url', scopedSlots: { customRender: 'url' }, align: 'center' },
            { title: '名称', dataIndex: 'name', scopedSlots: { customRender: 'name' }, align: 'center' },
            { title: '数量', dataIndex: 'amount', align: 'center' },
            { title: '单位', dataIndex: 'product_unit', width: 80, align: 'center' },
            { title: '订单价格', dataIndex: 'order_money', align: 'center' },
            { title: '折后价格', dataIndex: 'real_money', align: 'center' },
            { title: '状态', dataIndex: 'status', scopedSlots: { customRender: 'status' }, width: 120, align: 'center' },
          ]"
        >
          <span slot="url" slot-scope="text" style="display:flex;justify-content:center">
            <div class="image">
              <img mode="aspectFill" v-lazy="text" />
            </div>
          </span>
          <span slot="name" slot-scope="text, record">
            <router-link :to="{ name: 'product', query: { id: record.id_product_info }}">
              <a class="theme" style="text-decoration: underline;">{{ text }}</a>
            </router-link>
          </span>
          <!-- 订单状态 -->
          <span slot="status" slot-scope="text, record">
            <span :style="'color:'+status[record.status].color">{{ status[record.status].label }}</span>
          </span>
        </a-table>
      </a-table>
    </CustomForm>
    <!-- 分页器 -->
    <!-- <Pagination
      method="getAllMasterForFranchisee"
      ref="Pagination"
      :ok="setData"
      :loading="() => isLoadTable = true"
      :params="{ is_detail: true }"
    />-->
    <a-modal v-model="isShowModal" title="收付款信息" :showCancel="false" @ok="isShowModal = false">
      <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 18 }">
        <div v-if="modalData.pay_money!=modalData.order_money">
          <a-alert description="请及时向收款方转账付款，超时订单可能被平台取消。" type="warning" show-icon />
          <a-form-item label="待付金额" style="margin: 20px 0">
            <span style="color: #e6a23c">￥{{ modalData.unpay_money }}</span>
            <span style="color: #909399">（ 总金额￥{{ modalData.order_money }} ）</span>
          </a-form-item>
        </div>
        <div v-else>
          <a-form-item label="支付状态" style="margin: 20px 0">
            <span style="color: #67c23a">已付款</span>
            <span style="color: #909399">（ 总金额￥{{ modalData.order_money }} ）</span>
          </a-form-item>
        </div>
        <a-form-item label="收款方">{{ modalData.headquarters.company_name }}</a-form-item>
        <a-form-item label="收款户名">{{ modalData.headquarters.bank_account }}</a-form-item>
        <a-form-item label="开户行及账号">{{ modalData.headquarters.bank_number }}</a-form-item>
        <a-form-item label="付款方" style="margin: 20px 0 0">{{ modalData.franchisee.company_name }}</a-form-item>
        <a-form-item label="付款户名">{{ modalData.franchisee.bank_account }}</a-form-item>
        <a-form-item label="开户行及账号">{{ modalData.franchisee.bank_number }}</a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>
<script>
import CustomForm from '@/component/CustomForm.vue'
export default {
  data() {
    return {
      data: [], // 所有订单
      innerData: [],
      selectedData: [], // 选中的产品
      selectedDataKey: [], // 选中的产品id
      isShowModal: false,
      modalData: {
        headquarters: {},
        franchisee: {}
      }
    }
  },

  methods: {
    setData() {
      this.data.forEach((e) => {
        const unpay = e.real_money - e.pay_money
        e.unpay_money = unpay > 0 ? unpay.toFixed(2) : 0
        const deltaTime =
          new Date().getTime() - new Date(e.create_time).getTime()
        const day = Math.floor(deltaTime / 1000 / 60 / 60 / 24)
        const hour = Math.floor(deltaTime / 1000 / 60 / 60) % 24
        // const remainingDay = day > 14 ? '' : 14 - day + '天'
        // const remainingHour = day > 14 ? 0 : 23 - hour
        var remainingDay
        var remainingHour
        if (e.status === 3) {
          remainingDay = day > 3 ? '' : 2 - day + '天'
          remainingHour = day > 3 ? 0 : 23 - hour
        } else {
          remainingDay = day > 14 ? '' : 14 - day + '天'
          remainingHour = day > 14 ? 0 : 23 - hour
        }
        e.remaining_time = `剩余${remainingDay}${remainingHour}时`
      })
    },
    cancel(id) {
      this.isLoadTable = true
      this.post('cancelMasterForFranchisee', {
        id_order_master: id
      })
        .then((res) => {
          this.$message.success('订单已取消')
          this.$refs.CustomForm.toPage(1)
        })
        .catch((err) => {
          this.$message.error(err.msg)
          this.isLoadTable = false
        })
    },
    confirm(id) {
      this.isLoadTable = true
      this.post('confirmMasterForFranchisee', {
        id_order_master: id
      })
        .then((res) => {
          this.$message.success('金额已确认')
          this.$refs.CustomForm.toPage(1)
        })
        .catch((err) => {
          this.$message.error(err.msg)
          this.isLoadTable = false
        })
    },
    showModal(record) {
      record.franchisee = this.getUser()
      this.modalData = record
      this.isShowModal = true
    },
    showContract(order) {
      const routeData = this.$router.resolve({
        name: 'contract',
        query: { contract: order.code }
      })
      window.open(routeData.href, '_blank')
    }
  },

  mounted() {
    if (this.$route.params.unpay === 1) {
      this.isShowFirstOrder = true
    }
  },

  components: {
    CustomForm
  },

  props: ['status'],

  name: 'Unpaid'
}
</script>

<style scoped>
.table {
  min-height: 600px;
  max-width: 1600px;
  text-align: center;
}
.image {
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.image img {
  width: 100%;
  /* max-height: 60px; */
}
.buy {
  width: 100%;
  height: 50px;
  border-bottom: 1px solid #e7e7e7;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.buy .button {
  text-align: center;
  background: var(--theme);
  color: #fff;
  width: 200px;
  height: 50px;
  margin-left: 30px;
  line-height: 50px;
  font-size: 22px;
  cursor: pointer;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}
.buy .button:hover {
  transform: scale(1.03);
}
.ant-form-item {
  margin: 0;
}
/* 文字下划线 */
.text:hover {
  text-decoration: underline;
}
</style>
