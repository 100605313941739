<template>
  <a-spin
    class="max-width"
    :spinning="spinning"
    size="large"
    tip="加载中"
    style="padding: 0 20px; min-height: 600px"
  >
    <a-row type="flex" style="margin: 20px auto; max-width: 1600px;">
      <a-breadcrumb>
        <a-breadcrumb-item>
          <router-link :to="'/'">
            <a-icon type="home" />首页
          </router-link>
        </a-breadcrumb-item>
        <a-breadcrumb-item>我的订单</a-breadcrumb-item>
      </a-breadcrumb>
    </a-row>
    <a-tabs v-model="activeTab" default-active-key="2" @change="onTabChange">
      <a-tab-pane :forceRender="false" :key="13" tab="全部订单">
        <All v-if="activeTab===13" :status="status" />
      </a-tab-pane>
      <a-tab-pane :forceRender="false" :key="2" tab="订货单">
        <Unpaid v-if="activeTab===2" :status="status" />
      </a-tab-pane>
      <a-tab-pane :forceRender="false" :key="5" tab="收货单">
        <Received v-if="activeTab===5" :status="status" />
      </a-tab-pane>
      <a-tab-pane :forceRender="false" :key="9" tab="退货单">
        <Refund v-if="activeTab===9" :status="status" />
      </a-tab-pane>
      <a-tab-pane :forceRender="false" :key="6" tab=" 已取消">
        <Cancel v-if="activeTab===6" :status="status" />
      </a-tab-pane>
      <a-tab-pane :forceRender="false" :key="15" tab="定制订单">
        <Custom v-if="activeTab===15" :status="status" />
      </a-tab-pane>
    </a-tabs>
  </a-spin>
</template>
<script>
import Unpaid from '@/component/orders/Unpaid.vue'
import Received from '@/component/orders/Received.vue'
import Refund from '@/component/orders/Refund.vue'
import Cancel from '@/component/orders/Cancel.vue'
import All from '@/component/orders/All.vue'
import Custom from '@/component/orders/Custom.vue'
export default {
  components: {
    Unpaid,
    Received,
    Refund,
    Cancel,
    All,
    Custom
  },

  data() {
    return {
      spinning: false,
      activeTab: 13,
      noImg: 'index/noimg.png',
      status: [
        { color: '#909399', label: '已删除' }, // 0 灰色
        { color: '#2c3e50', label: '未下单' }, // 1 黑色
        { color: '#ff6633', label: '待支付' }, // 2 橙色
        { color: '#f56c6c', label: '付款待确认' }, // 3 红色
        // { color: '#e6a23c', label: '备货中' }, // 4 黄色
        { color: '#0099cc', label: '待发货' }, // 4 蓝色
        { color: '#0099cc', label: '待发货' }, // 5 蓝色
        { color: '#909399', label: '已取消' }, // 6 灰色
        { color: '#67c23a', label: '待收货' }, // 7 绿色
        { color: '#67c23a', label: '已收货' }, // 8 绿色
        { color: '#0099cc', label: '退货申请中' }, // 9 蓝色
        { color: '#f56c6c', label: '退货待发货' }, // 10 红色
        { color: '#2c3e50', label: '退货中' }, // 11 黑色
        { color: '#e6a23c', label: '待退款' }, // 12 黄色
        { color: '#67c23a', label: '已退货' }, // 13 绿色
        { color: '#909399', label: '拒绝退货' }, // 14 灰色
        { color: '#67c23a', label: '退货完成' } // 15 绿色
      ]
    }
  },

  methods: {
    onTabChange(key) {
      this.setSession('activeTab', key)
    }
  },

  mounted() {
    if (this.$route.params.tab) {
      this.setSession('activeTab', parseInt(this.$route.params.tab))
    }
    this.activeTab = this.getSession('activeTab') || 2
  }
}
</script>

<style scoped>
.max-width {
  /* margin: 20px auto; */
  /* padding: 0 50px; */
  max-width: 1600px;
  min-width: 900px;
}
.image {
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.image img {
  max-width: 100%;
  max-height: 100px;
}
.buy {
  width: 100%;
  height: 50px;
  border-bottom: 1px solid #e7e7e7;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.buy .button {
  text-align: center;
  background: var(--theme);
  color: #fff;
  width: 200px;
  height: 50px;
  margin-left: 30px;
  line-height: 50px;
  font-size: 22px;
  cursor: pointer;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}
.buy .button:hover {
  transform: scale(1.03);
}
</style>

<style>
.ant-tabs-bar {
  margin-bottom: 0 !important;
}
</style>
